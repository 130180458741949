<template>
    <div>
        <div class="actions__container">
            <div>
                <router-link
                    class="btn btn-primary w-40 inline-block mr-1 mb-2"
                    :to="{
                        name: 'faqCreate',
                    }"
                >
                    Создать
                </router-link>
            </div>
        </div>
        <ContentSearch />

        <ContentTable
            :table-actions="tableActions"
            :headers="tableHeaders"
            :table-rows="tableData"
            :meta="pagination"
            :loading="loading"
            @edit="editAction"
            @delete="deleteAction"
        />

        <PopupDeleteModal
            :is-open="modalDeleteOpen"
            :entity-name="modalDeleteCaption"
            :modal-data="modalDeleteRow"
            @update:isOpen="modalDeleteOpen = $event"
            @deleteModalAccepted="onDeleteModalAccepted"
        />
    </div>
</template>

<script>
import ContentSearch from '@/components/base/ContentSearch';
import { pageMixin } from '@/mixins/pageMixin';
import ContentTable from '@/components/tables/ContentTable';
import PopupDeleteModal from '@/components/modals/PopupDeleteModal';

export default {
    name: 'Main',
    components: { ContentSearch, ContentTable, PopupDeleteModal },
    mixins: [pageMixin],

    async beforeRouteUpdate(to, from, next) {
        await this.fetchData({ ...to.query });
        next();
    },

    data() {
        return {
            loading: false,
            tableData: [],
            modalDeleteOpen: false,
            modalDeleteCaption: null,
            modalDeleteRow: null,
            modalCallback: null,
            tableHeaders: [
                { field: 'id', label: '#' },
                { field: 'question', label: 'Название вопроса' },
            ],
            tableActions: [
                { name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' },
                { name: 'delete', title: 'удалить', class: 'bg-red-400', icon: 'Trash2Icon', cbName: 'delete' },
            ],
        };
    },

    async created() {
        this.$store.commit('main/setPageHeader', 'Вопросы и ответы');
        await this.fetchData();
    },

    methods: {
        deleteAction(row) {
            this.modalDeleteRow = row;
            this.modalDeleteCaption = `Вопрос ${row?.question}`;
            this.modalDeleteOpen = true;
        },

        editAction(row) {
            this._pMTransitionToEditPageWithQuery('faqEdit', { id: row.id });
        },

        async fetchData(params = { ...this.$route.query }) {
            try {
                this.loading = true;
                const { data } = await this.axios.get('/faq', { params });
                this.tableData = data.data.map((r) => {
                    return {
                        ...r,
                        ...{ images_count: r.images && r.images.length ? r.images.length : 'Нет' },
                    };
                });
                this.pagination = data.meta;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant fetch data: ' + ex);
            }
        },

        onDeleteModalAccepted(data) {
            this.axios
                .delete('/faq/' + data.id)
                .then(() => {
                    this.fetchData(this.$route.query);
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                })
                .finally(() => {
                    this.modalDeleteOpen = false;
                });
        },
    },
};
</script>
